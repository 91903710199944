export default {
  dashboard: {
    recruitment: "recruitment",
    onboarding: "onboarding",
    engagement: "engagement",
    development: "development",
    offboarding: "offboarding",
    alumni: "alumni"
  },
  reports: {
    candidates: "candidates",
    employees: "employees",
    participated: "participated",
    notParticipated: "notparticipated",
    feedback: "feedback",
    noFeedback: "nofeedback",
  },
  ttal: "obk-ttal",
  jigsaw: "obk-jigsaw",
  birthday: "obk-birthday",
  scavenger: "obk-scavenger-hunt",
  gtm: "obk-gtm",
  welcomeVideo: "obk-welcome-video",
  inductionVideo: "obk-induction-video",
  welcome360: "obk-welcome-360",
  documentLibrary: "obk-document-library",
  evp: "obk-evp",
  candidateExpSurvey: "obk-candidate-exp-survey",
  nha: "obk-new-hire-announcement",
  managerNPS: "obk-manager-nps",
  pulseOneWeek: "obk-pulse-check-1-week",
  expPaths: {
    "obk-ttal": "ReportsThreeTruths",
    "obk-jigsaw": "ReportsJigsaw",
    "obk-scavenger-hunt": "ReportsScavengerHunt",
    "obk-gtm": "ReportsGTM",
    "obk-welcome-video": "ReportsWelcomeVideo",
    "obk-induction-video": "ReportsInductionVideo",
    "obk-welcome-kit-ar": "ReportsWelcomeKitAR",
    "obk-document-library": "ReportsDocumentLibrary",
    "obk-evp": "ReportsEVP",
    "obk-new-hire-announcement": "ReportsNHA",
    "obk-pulse-check-1-week": "ReportsPulse1Week",
  },
  expFormPaths: {
    "obk-ttal": "ObkThreeTruths",
    "obk-jigsaw": "ObkJigsaw",
    "obk-scavenger-hunt": "ObkScavengerHunt",
    "obk-gtm": "ObkGTM",
    "obk-welcome-video": "ObkWelcomeVideo",
    "obk-induction-video": "ObkInductionVideo",
    "obk-welcome-kit-ar": "ObkWelcomeKitAR",
    "obk-document-library": "ObkDocumentLibrary",
    "obk-evp": "ObkEVP",
    "obk-new-hire-announcement": "ObkNHA",
    "obk-pulse-check-1-week": "ObkPulse1Week",
  },
  evpSlugs: {
    onboarding: {
      slug: "onboarding",
      label: "Onboarding",
      icon: "images/evp/icons/onboarding.svg",
    },
    compensation: {
      slug: "compensation",
      label: "Compensation",
      icon: "images/evp/icons/compensation.svg",
    },
    benefits: {
      slug: "benefits",
      label: "Benefits",
      icon: "images/evp/icons/benefits.svg",
    },
    career: {
      slug: "career",
      label: "Career",
      icon: "images/evp/icons/career.svg",
    },
    work_environment: {
      slug: "work_environment",
      label: "Work Environment",
      icon: "images/evp/icons/environment.svg",
    },
    culture: {
      slug: "culture",
      label: "Culture",
      icon: "images/evp/icons/culture.svg",
    },
    organization: {
      slug: "organization",
      label: "Organization",
      icon: "images/evp/icons/organization.svg",
    },
    offboarding: {
      slug: "offboarding",
      label: "Exit / Offboarding",
      icon: "images/evp/icons/exit.svg",
    },
    retiral_and_benefits: {
      slug: "retiral_and_benefits",
      label: "Retiral And Benefits",
      icon: "images/evp/icons/retiral.svg",
    },
  },
  evpOptions: {
    radial: "evpoption1",
    visual: "evpoption2",
    bubbles: "evpoption3"
  },
  nhaOptions: {
    option1: "nhaoption1",
    option2: "nhaoption2",
    option3: "nhaoption3",
    option4: "nhaoption4"
  },
  expJourneys: {
    candidateExperiences: "candidate-exps",
    fdawExperiences: "first-day-at-work",
    employeeExperiences: "employee-exps"
  },
  expJourneyNames: {
    candidateExperiences: "Candidate Experiences",
    fdawExperiences: "First Day at Work",
    employeeExperiences: "Employee Experiences"
  },
  tools: {
    celebrate: "tools-celebrate",
    jigsaw: "tools-jigsaw",
    scratchCard: "tools-scratch-card",
    spinTheWheel: "tools-spin-the-wheel"
  },
  celebrate: {
    promotion: "promotion"
  }
};
